import mobileNav from "./modules/mobileNav";
import arrowBtnAnimation from "./modules/arrowBtnAnimation";
import productBlurbAnimation from "./modules/productBlurbAnimation";
import Tooltip from "./modules/tooltip";
import mobileTooltip from "./modules/mobileTooltip";
import timelineGlider from "./modules/timelineGlider";
import {
    timelineOptions,
    reivewCarouselOptions,
} from "./modules/glideCarouselOptions";
import "lazysizes";

function init() {
    mobileNav();
    arrowBtnAnimation(); // Button Animation

    let hasGlide = document.querySelectorAll(".timelineSlider .glide").length;
    if (hasGlide > 0) {
        timelineGlider(".timelineSlider .glide", timelineOptions, false);
    }
    let footerGlide = document.querySelectorAll(".footerSliderV2.glide").length;
    if (footerGlide) {
        timelineGlider(".footerSliderV2.glide", reivewCarouselOptions, true);
    }

    if (document.querySelectorAll(".tooltipWrapper").length > 0) {
        document.querySelectorAll(".tooltipWrapper").forEach((tooltip) => {
            new Tooltip(tooltip).init();
        });
    }
    if (document.querySelectorAll(".mobileMacro").length > 0) {
        document.querySelectorAll(".mobileMacro").forEach((tooltip) => {
            mobileTooltip(tooltip);
        });
    }

    if (document.querySelector(".productBlurb")) {
        productBlurbAnimation();
    }
}

function ready() {
    if (document.readyState !== "loading") {
        init();
    } else {
        document.addEventListener("DOMContentLoaded", init);
    }
}

ready();
